@use '../tools/mixins/transitions' as *;

.c-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 100px 15px;

    &--fullscreen {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}

.c-loader {
    position: relative;
    width: 30px;
    height: 30px;
    -webkit-animation: rotation 2s infinite;
    animation: rotation 2s infinite;

    &__circle {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 5px;

        [class*='bg-'] & {
            background-color: #ffffff;
        }

        &--1 {
            left: 0;
            -webkit-animation: circle1 1s infinite alternate;
            animation: circle1 1s infinite alternate;
            background-color: var(--color-primary);

            @include transition-bg;
        }

        &--2 {
            right: 0;
            -webkit-animation: circle2 1s infinite alternate;
            animation: circle2 1s infinite alternate;
            background-color: var(--color-secondary);

            @include transition-bg;
        }

        &--3 {
            bottom: 0;
            -webkit-animation: circle3 1s infinite alternate;
            animation: circle3 1s infinite alternate;
            background-color: var(--color-secondary);

            @include transition-bg;
        }

        &--4 {
            right: 0;
            bottom: 0;
            -webkit-animation: circle4 1s infinite alternate;
            animation: circle4 1s infinite alternate;
            background-color: var(--color-primary);

            @include transition-bg;
        }
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes circle1 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(35px, 35px);
        transform: translate(35px, 35px);
    }
}

@keyframes circle1 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(35px, 35px);
        transform: translate(35px, 35px);
    }
}

@-webkit-keyframes circle2 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(-35px, 35px);
        transform: translate(-35px, 35px);
    }
}

@keyframes circle2 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(-35px, 35px);
        transform: translate(-35px, 35px);
    }
}

@-webkit-keyframes circle3 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(35px, -35px);
        transform: translate(35px, -35px);
    }
}

@keyframes circle3 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(35px, -35px);
        transform: translate(35px, -35px);
    }
}

@-webkit-keyframes circle4 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(-35px, -35px);
        transform: translate(-35px, -35px);
    }
}

@keyframes circle4 {
    from {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }

    to {
        -webkit-transform: translate(-35px, -35px);
        transform: translate(-35px, -35px);
    }
}
