.c-survey-intro {
    &__pretitle {
        margin-bottom: 3.125rem;
        font-size: 1.25rem;
        line-height: 1.2;
    }

    &__text {
        margin-bottom: 0.625rem;
    }

    &__title {
        margin-bottom: 1.875rem;
    }
}
