@use '../settings/variables' as vars;

.t-green-pink {
    --color-primary: #{vars.$green};
    --color-primary-darken: #{vars.$green-darken};
    --color-primary-rgb: #{vars.$green-rgb};
    --color-secondary: #{vars.$pink};
}

.t-blue-orange {
    --color-primary: #{vars.$blue};
    --color-primary-darken: #{vars.$blue-darken};
    --color-primary-rgb: #{vars.$blue-rgb};
    --color-secondary: #{vars.$orange};
}

.t-yellow-purple {
    --color-primary: #{vars.$yellow};
    --color-primary-darken: #{vars.$yellow-darken};
    --color-primary-rgb: #{vars.$yellow-rgb};
    --color-secondary: #{vars.$purple};
}

.t-orange-blue {
    --color-primary: #{vars.$orange};
    --color-primary-darken: #{vars.$orange-darken};
    --color-primary-rgb: #{vars.$orange-rgb};
    --color-secondary: #{vars.$blue};
}

.t-red-purple {
    --color-primary: #{vars.$red};
    --color-primary-darken: #{vars.$red-darken};
    --color-primary-rgb: #{vars.$red-rgb};
    --color-secondary: #{vars.$purple};
}

.t-pink-red {
    --color-primary: #{vars.$pink};
    --color-primary-darken: #{vars.$pink-darken};
    --color-primary-rgb: #{vars.$pink-rgb};
    --color-secondary: #{vars.$red};
}

.t-purple-green {
    --color-primary: #{vars.$purple};
    --color-primary-darken: #{vars.$purple-darken};
    --color-primary-rgb: #{vars.$purple-rgb};
    --color-secondary: #{vars.$green};
}
