@use '../settings/variables' as vars;

.c-alert {
    max-width: 700px;
    margin-top: 1.5625rem;
    margin-right: auto;
    margin-bottom: 1.5625rem;
    margin-left: auto;
    padding: 1.25rem;
    border: 2px solid vars.$text-color;
    border-radius: 0.1875rem;
    background: transparent;
    font-size: 1.5625rem;
    text-align: center;

    &--small {
        max-width: 100%;
        margin: 0;
        padding: 0.9375rem;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.142857;
    }

    [class*='bg-'] & {
        border-color: #ffffff;
        color: #ffffff;
    }
}

.c-alert-error {
    border-color: #e8564f;
    color: #e8564f;

    [class*='bg-'] & {
        border-color: transparent;
        background-color: #e8564f;
        color: #ffffff;
    }
}
