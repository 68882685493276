@use '../tools/mixins/breakpoints'as *;
@use '../tools/mixins/transitions' as *;

.c-breadcrumb {
    position: absolute;
    top: 3.375rem;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 670px;
    margin-right: auto;
    margin-left: 0;
    padding-right: 1.875rem;
    padding-left: 5rem;
    color: var(--color-primary);
    font-size: 1rem;
    line-height: 1.214286;

    @include transition-color();

    @include media-breakpoint-up(sm) {
        font-size: 1.125rem;
        line-height: 1.444444;
    }

    [class*='bg-'] & {
        margin-right: 0;
        margin-left: auto;
        color: #fff;
    }
}
