@use '../tools/mixins/breakpoints' as *;

.c-dashboard-header {
    padding: 2.9375rem 0 3.75rem;

    &__nav {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 3.125rem;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            margin-bottom: 4.875rem;
        }

        .c-breadcrumb {
            position: relative;
            top: initial;
            right: initial;
            left: initial;
            max-width: initial;
            margin-top: 1.25rem;
            padding: 0;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
                padding: 0 0 0 5rem;
                text-align: right;
            }
        }
    }

    &__logo {
        display: inline-flex;
        width: 185px;
        height: auto;
        color: #ffffff;

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;

        &__left {
            flex: 0 0 100%;

            @include media-breakpoint-up(sm) {
                flex: 1 1 auto;
            }
        }

        &__right {
            flex: 0 0 100%;
            min-width: 100%;
            margin-top: 3.125rem;

            @include media-breakpoint-up(sm) {
                flex: 0 0 364px;
                min-width: 364px;
            }
        }
    }

    &__illustration {
        display: block;
        width: 100%;
        max-width: 300px;
        margin-bottom: -3.75rem;
        margin-left: auto;

        @include media-breakpoint-up(sm) {
            max-width: 100%;
        }
    }

    &__title {
        max-width: 560px;
        margin-bottom: 1.875rem;
    }
}

.c-conversations-list {
    margin-top: 2.5rem;

    @include media-breakpoint-up(sm) {
        margin-top: 5rem;
    }

    &__title {
        font-size: 1.75rem;
        line-height: 1.357143;
    }

    &__list {
        margin: 0;
        padding-left: 0;
        list-style-type: none;
    }

    &__list-item {
        display: block;
        margin: 0;
        margin-bottom: 0.625rem;
        font-size: 1.125rem;
        line-height: 1.444444;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }

        &__names {
            display: block;

            @include media-breakpoint-up(sm) {
                display: inline;

                &::before {
                    content: '- ';
                }
            }
        }
    }

    .c-btn-link {
        margin-top: 1rem;
        padding: 0;

        @include media-breakpoint-up(sm) {
            margin-top: 1.5625rem;
        }
    }
}
