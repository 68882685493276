@mixin transition-color() {
    transition: color 0.15s ease-in-out;
}

@mixin transition-bg() {
    transition: background-color 0.15s ease-in-out;
}

@mixin transition-border() {
    transition: border-color 0.15s ease-in-out;
}

@mixin transition-box-shadow() {
    transition: box-shadow 0.15s ease-in-out;
}
