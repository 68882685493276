@font-face {
    font-family: 'Houschka';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('../../assets/fonts/houschkaaltmedium-webfont.woff2') format('woff2'),
        url('../../assets/fonts/houschkaaltmedium-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Houschka';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url('../../assets/fonts/houschkaaltbold-webfont.woff2') format('woff2'),
        url('../../assets/fonts/houschkaaltbold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Houschka';
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    src: url('../../assets/fonts/houschkaaltmediumitalic-webfont.woff2') format('woff2'),
        url('../../assets/fonts/houschkaaltmediumitalic-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Houschka';
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    src: url('../../assets/fonts/houschkaaltbolditalic-webfont.woff2') format('woff2'),
        url('../../assets/fonts/houschkaaltbolditalic-webfont.woff') format('woff');
}
