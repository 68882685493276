@use '../settings/variables' as vars;
@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.c-dashboard-tabs {
    padding-bottom: 6.0625rem;
}

.c-tabs-nav {
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #ffffff;
    background-color: var(--color-primary);

    @include transition-bg();

    @include media-breakpoint-up(sm) {
        border-top: 2px solid #ffffff;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    &__list-item {
        flex-grow: 1;
        margin-right: -1.25rem;
        margin-left: -1.25rem;
        border-top: 2px solid #ffffff;

        @include media-breakpoint-up(sm) {
            margin-right: 0;
            margin-left: 0;
            border-top: 0;
        }
    }

    &__list-item-link {
        display: block;
        width: 100%;
        padding: 1.0625rem 0.9375rem 1.1875rem;
        border: 0;
        background-color: var(--color-primary);
        color: #ffffff;
        font-family: vars.$font-family-base;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.166667;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        @include transition-bg();

        &:hover,
        &:focus {
            outline: none;
            text-decoration: underline;
        }

        .is-active & {
            background-color: #ffffff;
            color: var(--color-primary);

            @include transition-color();
        }
    }
}

.c-tabs-content {
    display: none;

    &.is-active {
        display: block;
    }
}

.c-dashboard-topic-block {
    margin-top: 3.125rem;
    margin-bottom: 4.375rem;

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: 1.25rem;
        font-size: 1.875rem;
        line-height: 1.266667;

        @include media-breakpoint-up(sm) {
            margin-right: 3.125rem;
            margin-left: 3.125rem;
        }
    }
}

.c-dashboard-question-block {
    margin-bottom: 1.875rem;
    padding: 2.5rem 1.5625rem;
    border-radius: 0.3125rem;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(154, 154, 154, 0.29);

    @include media-breakpoint-up(sm) {
        margin-bottom: 3.125rem;
        padding: 2.625rem 3.125rem 3.125rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        max-width: 680px;
        font-size: 1.375rem;
        line-height: 1.454545;
    }

    .c-btn-link {
        padding: 0;
    }
}

.c-dashboard-open-answers {
    position: relative;
    max-width: 680px;

    &__overlap {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 75px;
        transition: background 0.5s cubic-bezier(0, 1, 0, 1);
        background: rgb(255, 255, 255);
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.85) 50%,
            rgba(255, 255, 255, 0) 100%
        );
    }

    &.is-open {
        margin-bottom: 1.875rem;
    }
}

.c-dashboard-open-answer {
    margin-bottom: 1.875rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__info {
        display: block;
        margin-bottom: 0.625rem;
        color: var(--color-primary);
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;

        @include transition-color();
    }

    &__text {
        margin-bottom: 0;
        font-size: 1.125rem;
        line-height: 1.333333;
    }
}

.c-cta-blocks {
    margin-top: 2.3125rem;
    margin-bottom: 3.75rem;

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -0.625rem;
        margin-bottom: -2.3125rem;
        margin-left: -0.625rem;
    }

    &__col {
        flex: 0 0 100%;
        min-width: 100%;
        margin-bottom: 2.3125rem;
        padding-right: 0.625rem;
        padding-left: 0.625rem;

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            min-width: 50%;
        }
    }
}

.c-cta-block {
    height: 100%;
    padding: 1.875rem 1.25rem;
    border-radius: 0.3125rem;
    background-color: var(--color-primary);
    color: #ffffff;
    text-align: center;

    @include transition-bg();

    @include media-breakpoint-up(sm) {
        padding: 3.125rem;
    }

    &__title {
        margin-bottom: 2rem;
        font-size: 2rem;
        line-height: 1.2;

        @include media-breakpoint-up(md) {
            font-size: 2.5rem;
            line-height: 1.35;
        }
    }

    .c-btn {
        white-space: normal;
    }
}
