@use '../settings/variables' as vars;
@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.u-fill-primary {
    color: var(--color-primary);

    @include transition-color();
}

.u-fill-secondary {
    color: var(--color-secondary);

    @include transition-color();
}

.u-text-white {
    color: #ffffff;
}

.u-text-center {
    text-align: center;
}

.u-text-large {
    font-size: 1.375rem;
    line-height: 1.357143;

    @include media-breakpoint-up(sm) {
        font-size: 1.75rem;
    }
}
