@use '../settings/variables' as vars;

.c-btn-group {
    margin: -0.46875rem;

    .c-btn,
    .c-btn-link {
        margin: 0.46875rem;
    }

    &--list .c-btn {
        display: flex;
        margin-right: auto;
        margin-bottom: 0.875rem;
        margin-left: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
