@use '../settings/variables' as vars;
@use '../tools/mixins/breakpoints' as *;

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    color: vars.$text-color;
    font-family: vars.$font-family-base;
    font-size: 1.125rem;
    line-height: 1.5;

    @include media-breakpoint-up(sm) {
        font-size: vars.$font-size-base;
        line-height: vars.$line-height-base;
    }
}

#root {
    position: relative;
    min-height: 100vh;
}
