@use '../tools/mixins/transitions' as *;

.c-create-survey-form {
    max-width: 560px;
    margin: 2.6875rem auto 0;

    .c-form-label {
        margin-bottom: 1.125rem;
        color: var(--color-primary);
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.083333;

        @include transition-color();
    }
}
