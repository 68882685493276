.c-create-survey-intro {
    max-width: 773px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    &__illustration {
        max-width: 347px;
        margin-right: auto;
        margin-bottom: 1.875rem;
        margin-left: auto;
    }

    &__text {
        max-width: 680px;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
    }
}
