@use '../tools/mixins/breakpoints' as *;

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

h1 {
    margin-bottom: 0.625rem;
    font-size: 2rem;
    line-height: 1.1875;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.25rem;
        font-size: 3rem;
        line-height: 1.083333;
    }
}
