@use '../settings/variables'as vars;
@use '../tools/mixins/breakpoints'as *;

.c-form {
    display: flex;
    flex-wrap: wrap;
}

.c-form .c-btn {
    margin-left: auto;
}

.c-form-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.3;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.1875rem;
        font-size: 1.25rem;
    }
}

.c-form-group {
    flex: 0 0 100%;
    min-width: 100%;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 3.125rem;
    }
}

.c-form-group:last-of-type {
    margin-bottom: 1.25rem;
}

@include media-breakpoint-up(sm) {
    .c-form-group.c-form-group--radio .c-form-label {
        margin-bottom: 1.75rem;
    }
}

.c-form-input {
    display: block;
    width: 100%;
    padding: 0.4375rem 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 2px solid #efefef;
    border-radius: 0.1875rem;
    background-color: #ffffff;
    color: vars.$text-color;
    font-family: vars.$font-family-base;
    font-size: 1.125rem;
    line-height: 1.5;
    appearance: none;

    @include media-breakpoint-up(sm) {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem #f5f5f5;
    }

    .has-error & {
        border-color: #e8564f;
        color: #e8564f;

        &:focus {
            box-shadow: 0 0 0 0.25rem #f8ccca;
        }
    }
}

.c-form-error {
    margin-top: 0.3125rem;
    margin-left: 1.125rem;
    color: #e8564f;
    font-size: 0.875rem;
    line-height: 1.142857;
}

::placeholder {
    opacity: 1;
    color: #b9b9b9;
}

:-ms-input-placeholder {
    color: #b9b9b9;
}

::-ms-input-placeholder {
    color: #b9b9b9;
}
