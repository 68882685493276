@use '../tools/mixins/breakpoints'as *;
@use '../tools/mixins/transitions' as *;

.c-dialog {
    display: flex;
    position: fixed;
    z-index: 1050;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    outline: 0;

    &__inner {
        position: relative;
        width: auto;
        max-width: 740px;
        margin: 1.75rem 1.875rem;
    }

    .c-btn-group {
        margin: -0.46875rem -0.78125rem;

        .c-btn,
        .c-btn-link {
            margin: 0.46875rem 0.78125rem;
        }
    }
}

.c-dialog-content {
    position: relative;
    width: 100%;
    padding: 4.375rem 1.875rem 1.875rem;
    border-radius: 0.3125rem;
    outline: 0;
    background-color: #ffffff;

    @include media-breakpoint-up(sm) {
        padding: 5.1875rem 4.0625rem 3.125rem;
    }
}

.c-dialog-title {
    max-width: 520px;
    margin-bottom: 0.625rem;
    font-size: 1.5rem;
    line-height: 1.178571;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.6875rem;
        font-size: 1.75rem;
    }
}

.c-dialog-text {
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
    line-height: 1.444444;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.875rem;
    }
}

.c-dialog-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    background-color: var(--color-primary);
    color: #ffffff;

    @include transition-bg;

    svg {
        width: 0.875rem;
        height: 0.875rem;
    }
}

.c-dialog-backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: #1b1b1b;
}
