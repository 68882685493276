.u-list-inline {
    margin-top: 0;
    padding-left: 0;
    list-style: none;
}

.u-list-inline-item {
    display: inline-block;
}

.u-list-inline-item:not(:last-child) {
    margin-right: 1rem;
}
