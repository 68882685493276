@use '../tools/mixins/breakpoints' as *;

.o-container,
.o-container-small {
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;

    @include media-breakpoint-up(sm) {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
}

.o-container {
    max-width: 1340px;
}

.o-container-small {
    max-width: 833px;
}
