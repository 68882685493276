@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.c-form-checkbox {
    margin-bottom: 0.625rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 2.0625rem;
    }

    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    &__label {
        display: inline-flex;
        position: relative;
        width: 100%;
        padding: 0.625rem;
        border: 0.125rem solid #efefef;
        border-radius: 0.1875rem;
        line-height: 1;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            width: auto;
            padding: 0;
            border: 0;
            border-radius: 0;
        }
    }

    &__control {
        content: '';
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        margin-right: 0.875rem;
        border: 2px solid var(--color-primary);
        border-radius: 0.125rem;

        @include transition-border;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            transform: scale(0);
            transition: 180ms transform ease-in-out, background-color 0.2s ease-in-out;
            background-color: var(--color-primary);
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.988 9.434a1.172 1.172 0 0 1-.294-.272c-.051-.047-.1-.1-.143-.158L.421 4.851a1.174 1.174 0 0 1 1.876-1.414L4.545 6.42 8.303.634a1.174 1.174 0 1 1 1.97 1.28l-4.66 7.175a1.174 1.174 0 0 1-1.625.345Z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__input:focus + &__label > &__control {
        box-shadow: 0 0 0 0.25rem rgba(var(--color-primary-rgb), 0.35);

        @include transition-box-shadow();
    }

    &__input:checked + &__label > &__control::before {
        transform: scale(1);
    }
}
