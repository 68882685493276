@use '../settings/variables' as vars;
@use '../tools/mixins/transitions' as *;

.c-tag {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem 0.3125rem 0.75rem;
    border: 0.125rem solid var(--color-primary);
    border-radius: 0.1875rem;
    background-color: var(--color-primary);
    color: #ffffff;
    font-family: vars.$font-family-base;
    font-size: 1.125rem;
    line-height: 1.166667;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;

    @include transition-border;
    @include transition-bg;

    &--white {
        border-color: #ffffff;
        background-color: transparent;
        color: #ffffff;
    }

    &--secondary {
        border-color: var(--color-secondary);
        background-color: var(--color-secondary);
        color: #ffffff;
    }
}
