@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.c-themes {
    margin-bottom: 1.875rem;
    color: var(--color-primary);
    font-size: 1.75rem;
    line-height: 1.357143;

    @include transition-color;


    &__title,
    &__list {
        font-size: 1.4375rem;
        font-weight: bold;
        line-height: 1.357143;

        @include media-breakpoint-up(sm) {
            font-size: 1.75rem;
        }
    }

    &__title {
        margin-bottom: 0.625rem;
    }

    &__list {
        margin: 0;
        padding-left: 0;

        li {
            list-style-position: inside;
            line-height: 1.607143;
        }
    }
}
