@use '../settings/variables' as vars;
@use '../tools/mixins/transitions' as *;

.c-btn-link {
    position: relative;
    padding: 0.3333em 0.8333em 0.3888em 0.6666em;
    padding-right: 0;
    padding-left: 0;
    transition: all 0.15s ease-in-out;
    border: 0px solid transparent;
    border-radius: 0.166em;
    background-color: transparent;
    color: var(--color-primary);
    font-family: vars.$font-family-base;
    font-size: 1.125rem;
    line-height: 1.166667;
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    text-underline-position: under;
    text-decoration-thickness: 0.125rem;
    text-underline-offset: 0.125rem;

    &--white {
        color: #ffffff;

        &:hover,
        &:focus {
            color: rgba(255, 255, 255, 0.85);
        }
    }

    .c-minus-icon,
    .c-plus-icon {
        display: inline-flex;
        width: 0.625rem;
        height: 0.625rem;
        margin-right: -0.625rem;

        + span::before {
            content: '\00a0\00a0\00a0\00a0';
        }
    }
}
