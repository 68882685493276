@use '../tools/mixins/breakpoints'as *;

.c-conversation-finish {
    &__title {
        margin-bottom: 2.0625rem;
    }

    &__text {
        max-width: 773px;
        margin-right: auto;
        margin-bottom: 2.625rem;
        margin-left: auto;
    }

    .c-breadcrumb {
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0 1.25rem;
            text-align: center;
        }

        @include media-breakpoint-up(sm) {
            text-align: right;
        }
    }
}
