@use '../settings/variables' as vars;
@use '../tools/mixins/transitions' as *;

.c-btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    padding: 0.25rem 0.75rem 0.3125rem 0.75rem;
    transition: all 0.15s ease-in-out;
    border: 0.125rem solid transparent;
    border-radius: 0.1875rem;
    background-color: var(--color-primary);
    color: #ffffff;
    font-family: vars.$font-family-base;
    font-size: 1.125rem;
    line-height: 1.166667;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: var(--color-primary-darken);
        color: #ffffff;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem rgba(var(--color-primary-rgb), 0.35);
    }

    svg {
        display: inline-flex;
    }

    .c-arrow-icon {
        width: 0.625rem;
        height: 0.625rem;

        &:first-child {
            margin-right: 0.75rem;
            transform: rotate(180deg);
        }

        &:last-child {
            margin-left: 0.75rem;
        }
    }

    .c-copy-icon {
        width: 0.8125rem;
        height: 0.8125rem;
        margin-left: 0.5rem;
    }

    &--white {
        background-color: #ffffff;
        color: var(--color-primary);

        &:hover {
            background-color: rgba(255, 255, 255, 0.85);
            color: var(--color-primary);
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.35);
        }
    }

    &--white-outline {
        border-color: #ffffff;
        background-color: transparent;
        color: #ffffff;

        &:hover {
            background-color: #ffffff;
            color: var(--color-primary);
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.35);
        }
    }

    &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
