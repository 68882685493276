@use '../settings/variables' as vars;

.c-copy-feedback {
    display: inline-flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    align-items: center;
    width: 190px;
    margin-left: -95px;
    padding: 0.25rem 0.75rem 0.3125rem 0.75rem;
    transform: translateY(150%);
    transition: opacity 0.15s ease-in-out;
    border: 0.125rem solid transparent;
    border-radius: 0.1875rem;
    opacity: 0;
    background-color: rgba(vars.$text-color, 0.9);
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.166667;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    cursor: default;
    pointer-events: none;

    svg {
        width: auto;
        height: 0.625rem;
        margin-right: 0.3125rem;
    }

    &.show {
        opacity: 0.8;
    }
}
