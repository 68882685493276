@use '../tools/mixins/breakpoints'as *;

.c-pie-chart {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3.125rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-end;
    }

    &__chart {
        position: relative;
        max-width: 370px;
        max-height: 370px;

        @include media-breakpoint-up(md) {
            margin-right: 5rem;
        }
    }

    &__legend {
        flex: 0 0 auto;
        margin: 1.875rem 0 0 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 5.625rem 0;
        }

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.9375rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 2.1875rem;
            }

            span {
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.625rem;
                border-radius: 0.1875rem;
            }

            p {
                margin: 0;
                padding: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
