figcaption,
figure,
article,
img,
svg {
    display: block;
    max-width: 100%;
}

figure {
    margin: 0;
}
