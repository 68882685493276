// BASE COLORS
$text-color: #191919;

// THEME COLORS
$green: #a1d078;
$green-darken: #53951a;
$green-rgb: 161, 208, 120;

$blue: #7ac3e7;
$blue-darken: #3e9ac7;
$blue-rgb: 122, 195, 231;

$pink: #fbbee6;
$pink-darken: #ce60a8;
$pink-rgb: 251, 190, 230;

$orange: #f58349;
$orange-darken: #d95d1e;
$orange-rgb: 245, 131, 73;

$purple: #917fee;
$purple-darken: #4c3fa2;
$purple-rgb: 145, 127, 238;

$yellow: #ffc943;
$yellow-darken: #f88b02;
$yellow-rgb: 255, 201, 67;

$red: #e8564f;
$red-darken: #c6271f;
$red-rgb: 232, 86, 79;

// FONT BASE
$font-family-base: 'Houschka', Arial;
$font-size-base: 1.25rem;
$line-height-base: 1.3;

// FONT WEIGHT
$font-weight-normal: normal;
$font-weight-bold: bold;

// BREAKPOINTS
$grid-breakpoints: (
    xs: 414px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);
