@use '../settings/variables' as vars;
@use '../tools/mixins/breakpoints'as *;
@use '../tools/mixins/transitions' as *;

.c-footer {
    position: absolute;
    bottom: 1.875rem;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        bottom: 3.125rem;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.c-footer-menu {
    width: 100%;
    margin-top: 1.25rem !important;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        width: auto;
    }
}

.c-footer-logo {
    display: inline-flex;
    width: 167px;
    height: auto;
    margin-right: 1.875rem;
    color: var(--color-primary);

    @include transition-color;

    @include media-breakpoint-up(sm) {
        width: 185px;
    }

    @include media-breakpoint-up(md) {
        .is-splitted & {
            color: #ffffff;
        }
    }

    svg {
        width: 100%;
        height: auto;
    }

    [class*='bg-'] & {
        color: #ffffff;
    }
}

.c-footer-link {
    transition: opacity 0.15s ease-in-out;
    opacity: 0.5;
    color: vars.$text-color;
    font-size: 0.875rem;
    text-underline-offset: 3px;

    &:hover {
        opacity: 0.7;
        color: vars.$text-color;

        [class*='bg-'] & {
            opacity: 1;
            color: #ffffff;
        }
    }

    [class*='bg-'] & {
        opacity: 0.7;
        color: #ffffff;
    }
}
