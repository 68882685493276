@use '../tools/mixins/breakpoints' as *;

.c-question-tags {
    width: 100%;
    margin: -0.25rem -0.4375rem;
    margin-bottom: 0.8125rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 1.6875rem;
    }

    .c-tag {
        margin: 0.1875rem 0.4375rem;
    }
}

.c-question-title {
    font-size: 1.6875rem;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
        font-size: 1.875rem;
        line-height: 1.266667;
    }
}

.c-questions-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-bottom: 2.5rem;
    margin-left: auto;
    padding-left: 0;

    &__index {
        height: 2.125rem;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        top: 3.125rem;
        right: 0;
        left: 0;
        max-width: 670px;
        margin-right: 0;
        margin-bottom: 0;
        padding-right: 5rem;
        padding-left: 1.875rem;
    }
}
