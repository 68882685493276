@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.c-form-radio {
    margin-bottom: 0.625rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 2.0625rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    &__label {
        display: inline-flex;
        position: relative;
        width: 100%;
        padding: 0.625rem;
        border: 0.125rem solid #efefef;
        border-radius: 0.1875rem;
        font-size: 1.25rem;
        line-height: 1.2;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            width: auto;
            padding: 0;
            border: 0;
            border-radius: 0;
        }
    }

    &__control {
        content: '';
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        margin-top: 0.25rem;
        margin-right: 0.875rem;
        border: 0.125rem solid var(--color-primary);
        border-radius: 50%;

        @include transition-border();

        &::before {
            content: '';
            display: block;
            flex: 0 0 auto;
            width: 0.5rem;
            height: 0.5rem;
            transform: scale(0);
            transition: 180ms transform ease-in-out, box-shadow 0.2s ease-in-out;
            border-radius: 50%;
            box-shadow: inset 0.5em 0.5em var(--color-primary);
        }
    }

    &__input:focus + &__label > &__control {
        box-shadow: 0 0 0 0.25rem rgba(var(--color-primary-rgb), 0.35);

        @include transition-box-shadow();
    }

    &__input:checked + &__label > &__control::before {
        transform: scale(1);
    }
}
