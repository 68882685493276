@use '../../settings/variables' as vars;

@function breakpoint-next(
    $name,
    $breakpoints: vars.$grid-breakpoints,
    $breakpoint-names: map-keys($breakpoints)
) {
    $n: index($breakpoint-names, $name);

    @if not $n {
        @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
    }

    @return if(
        $n < length($breakpoint-names),
        nth($breakpoint-names, $n + 1),
        null
    );
}

@function breakpoint-min($name, $breakpoints: vars.$grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0, $min, null);
}

@function breakpoint-max($name, $breakpoints: vars.$grid-breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - 0.02, null);
}

@function breakpoint-infix($name, $breakpoints: vars.$grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) ==null, '', '-#{$name}');
}

@mixin media-breakpoint-up($name, $breakpoints: vars.$grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: vars.$grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}
