@use '../tools/mixins/breakpoints'as *;

.c-split-pane {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-up(md) {
        min-height: 100vh;
    }
}

.c-split-pane-left,
.c-split-pane-right {
    flex: 0 0 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
        flex: 0 0 auto;
        width: 50%;
    }
}

.c-split-pane-container {
    position: relative;
    max-width: 670px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 3.125rem;
    padding-right: 1.25rem;
    padding-bottom: 3.125rem;
    padding-left: 1.25rem;

    @include media-breakpoint-up(sm) {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }

    @include media-breakpoint-up(md) {
        min-height: 100vh;
        padding-top: 9.375rem;
        padding-bottom: 7.9375rem;
    }
}

.c-split-pane-left {
    .c-split-pane-container {
        padding-bottom: 4.375rem;

        @include media-breakpoint-up(md) {
            margin-right: 0;
            padding-right: 5rem;
            padding-bottom: 3.125rem;
        }
    }
}

.c-split-pane-right {
    .c-split-pane-container {
        padding-top: 4.375rem;
        padding-bottom: 11.25rem;

        @include media-breakpoint-up(md) {
            margin-left: 0;
            padding-top: 9.375rem;
            padding-left: 5rem;
        }
    }

    .c-btn {
        display: flex;
        margin-left: auto;
    }

    .c-breadcrumb {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.c-split-pane-illustration {
    position: absolute;
    bottom: 0;
    max-width: 143px;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(50%);

    @include media-breakpoint-up(md) {
        position: relative;
        max-width: 285px;
        margin-top: 8.125rem;
        margin-right: -8.125rem;
        transform: none;
    }

    svg {
        width: 100%;
        height: auto;
    }
}
