@use '../tools/mixins/breakpoints' as *;
@use '../tools/mixins/transitions' as *;

.c-bar-chart {
    position: relative;

    &__overlay {
        content: '';
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgb(255 255 255 / 50%);

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &__overlay-icon {
        width: 80px;
        height: 80px;
        padding: 20px;
        border-radius: 80px;
        background-color: var(--color-primary);
        color: #ffffff;

        @include transition-bg();

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__wrapper {
        overflow-x: auto;
    }

    &__wrapper-inner {
        width: 950px;

        @include media-breakpoint-up(xl) {
            width: 100%;
        }
    }
}
