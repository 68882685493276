@use '../settings/variables' as vars;

:root {
    --color-text: #{vars.$text-color};
    --color-primary: #{vars.$green};
    --color-primary-darken: #{vars.$green-darken};
    --color-primary-rgb: #{vars.$green-rgb};
    --color-secondary: #{vars.$pink};
    font-size: 1rem;
}
