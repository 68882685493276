@use '../tools/mixins/breakpoints' as *;

.o-content {
    min-height: 100vh;
    padding: 3.125rem 0 11.25rem;

    @include media-breakpoint-up(sm) {
        padding: 6.6875rem 0 7.9375rem;
    }
}

.o-content--center {
    display: flex;
    align-items: center;
}

.o-content--fullwidth {
    padding: 0;
}
